import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { cutDescription } from '@elo-kit/utils/product.utils'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'
import { SELLER_PRODUCTS_LIST_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { PRODUCTS_LIST } from '@elo-kit/constants/contentPage.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'

import listSellerProductsStyles from 'shared/components/content-page/preview/blocks/seller-products/ListSellerProducts.styles'

/**
 * Product price component for product
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ProductPrice = (props) => {
  const {
    classes,
    convertToPrice,
    getUnit,
    mobile,
    product,
    freeDefaultText,
    convertToPriceWithoutCurrency,
    previewMode,
  } = props

  const containerClasses = classNames({
    [classes.listPriceContainer]: !mobile,
    [classes.mobileListPriceContainer]: mobile,
  })
  const { displayPrice, displayOldPrice, displayCurrencyId } = product
  const currencySign = getUnit(displayCurrencyId)

  return (
    <div className={containerClasses}>
      {displayOldPrice > 0 && (
        <div
          className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.listOldPriceClassName, classes.oldPriceContainer)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockOldPrice,
            'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listOldPriceClassName}`,
            'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
          })}
        >
          <div className={classes.oldPrice} translate='no'>
            <div>
              <span>{convertToPrice(displayOldPrice, displayCurrencyId)}</span>
              <hr className={classes.oldPriceLine} />
            </div>
          </div>
        </div>
      )}
      <span
        className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.listPriceClassName, classes.price)}
        translate='no'
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockPrice,
          'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listPriceClassName}`,
          'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
        })}
      >
        {product.free ? freeDefaultText : convertToPriceWithoutCurrency(displayPrice)}
      </span>
      {!product.free && (
        <span
          className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.listCurrencyClassName, classes.currency)}
          translate='no'
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockCurrency,
            'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listCurrencyClassName}`,
            'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
          })}
        >
          {currencySign}
        </span>
      )}
    </div>
  )
}

ProductPrice.propTypes = {
  /** Product price classes */
  classes: PropTypes.object,
  /** Convert price function received from currency store */
  convertToPrice: PropTypes.func,
  /** Get unit function received from currency store */
  getUnit: PropTypes.func,
  /** Product */
  product: PropTypes.object,
  /** Default text for free product */
  freeDefaultText: PropTypes.string, // I18n.t('react.shared.free')
  /** Property for add class in mobile view */
  mobile: PropTypes.bool,
  /** Convert price without currency function */
  convertToPriceWithoutCurrency: PropTypes.func, // (value) => value.formatMoney(2, ',', '.')
  /** Preview mode in page builder */
  previewMode: PropTypes.bool,
}

ProductPrice.defaultProps = {
  freeDefaultText: 'Free',
  product: {},
  classes: {},
  getUnit: /* istanbul ignore next */ () => {},
  convertToPrice: /* istanbul ignore next */ () => {},
  convertToPriceWithoutCurrency: /* istanbul ignore next */ () => {},
  previewMode: false,
}

/**
 * List seller product view
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ListSellerProducts = (props) => {
  const {
    classes,
    productsList,
    userName,
    previewMode,
    content,
    handleBuyProduct,
    LinkComponent,
    buyNowText,
    convertToPrice,
    getUnit,
    convertToPriceWithoutCurrency,
    freeDefaultText,
    productsContent,
  } = props

  return (
    <div
      className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName, classes.list)}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productsView,
        'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listImageClassName}`,
      })}
    >
      {productsList.map((product) => {
        const { description } = product
        const titleClasses = classNames(
          classes.productTitle,
          classes.listProductTitle,
          SELLER_PRODUCTS_LIST_CLASS_NAMES.listTitleClassName
        )

        const buttonClasses = classNames(
          classes.button,
          classes.listButton,
          {
            [classes[content.buttonAnimation]]: !!content.buttonAnimation,
          },
          SELLER_PRODUCTS_LIST_CLASS_NAMES.listButtonClassName
        )

        const productDescription = cutDescription(description)
        const covers = productsContent.find((image) => image.id === product.covers?.[0]?.id)?.attributes || {}

        const link = product.slug ? `/s/${userName}/${product.slug}` : `/s/${userName}`
        const linkClickHandler = (e) => {
          if (previewMode) {
            e.preventDefault()
          } else {
            const isNextApp = getElopageConfig('isNextApp')

            if (!isNextApp) {
              window.scrollTo(0, 0)
            }
          }
        }

        return (
          <div
            key={product.id}
            className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.listItemClassName, classes.item)}
          >
            <div className={classes.productDetailsContainer}>
              <LinkComponent
                to={link}
                onClick={linkClickHandler}
                className={classNames(
                  classes.listBackgroundImageContainer,
                  SELLER_PRODUCTS_LIST_CLASS_NAMES.listImageClassName
                )}
              >
                {covers.s640 || covers.s100 ? (
                  <LazyBackgroundImage
                    isResizable
                    block={{
                      cover: {
                        file: {
                          original: covers.s640,
                          s640: covers.s100,
                        },
                      },
                    }}
                    wrapperClassName={classNames(classes.productCover)}
                  />
                ) : (
                  <i className={classNames('far fa-image')} />
                )}
              </LinkComponent>
              <LinkComponent to={link} onClick={linkClickHandler} className={classes.listProductDetails}>
                <div
                  className={titleClasses}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.productsTitles,
                    'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listTitleClassName}`,
                    'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
                  })}
                >
                  {product.name}
                </div>
                <div
                  className={classNames(classes.description, SELLER_PRODUCTS_LIST_CLASS_NAMES.listDescriptionClassName)}
                  dangerouslySetInnerHTML={{
                    __html: cleanupHTMLStyleFonts(productDescription),
                  }}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.blockProductDescription,
                    'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listDescriptionClassName}`,
                    'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
                  })}
                />
                <ProductPrice
                  mobile
                  {...{
                    product,
                    classes,
                    convertToPrice,
                    getUnit,
                    convertToPriceWithoutCurrency,
                    freeDefaultText,
                    previewMode,
                  }}
                />
              </LinkComponent>
            </div>
            <div
              className={classNames(SELLER_PRODUCTS_LIST_CLASS_NAMES.listDetailsClassName, classes.payInfoContainer)}
            >
              <ProductPrice
                {...{
                  product,
                  classes,
                  convertToPrice,
                  getUnit,
                  convertToPriceWithoutCurrency,
                  freeDefaultText,
                  previewMode,
                }}
              />
              {content[PRODUCTS_LIST.showButton] && (
                <button
                  type='button'
                  onClick={(e) => handleBuyProduct(product, e)}
                  className={buttonClasses}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.sellerProducts.buyNowButton,
                    'data-highlighter-selector': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listButtonClassName}`,
                    'data-highlighter-parent': `.${SELLER_PRODUCTS_LIST_CLASS_NAMES.listContainerClassName}`,
                  })}
                >
                  {content[PRODUCTS_LIST.buttonText] || buyNowText}
                </button>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

ListSellerProducts.propTypes = {
  /** Classes object for list seller products */
  classes: PropTypes.object,
  /** Username */
  userName: PropTypes.string,
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
  /** List of products */
  productsList: PropTypes.array,
  /** Buy product function */
  handleBuyProduct: PropTypes.func,
  /** Content object */
  content: PropTypes.object,
  /** Buy now button text */
  buyNowText: PropTypes.string, // I18n.t('shared.common.buy_now')
  /** Wrapper link for url */
  LinkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Convert price function received from currency store */
  convertToPrice: PropTypes.func,
  /** Get unit function received from currency store */
  getUnit: PropTypes.func,
  /** Product */
  product: PropTypes.object,
  /** Default text for free product */
  freeDefaultText: PropTypes.string, // I18n.t('react.shared.free')
  /** Convert price without currency function */
  convertToPriceWithoutCurrency: PropTypes.func, // (value) => value.formatMoney(2, ',', '.')
}

ListSellerProducts.defaultProps = {
  buyNowText: 'Buy now',
  classes: {},
  content: {},
  LinkComponent: 'a',
  productsList: [],
}

export default withStyles(listSellerProductsStyles)(ListSellerProducts)
