import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'
import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'
import { EDIT_TEXT_FIELD_TYPES } from 'shared/components/page-builder/constants/pageBuilder.constants'

import { TEXT_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { removeScriptTagsWithSrcAttibute } from 'utils/froala.utils'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

export const Text = ({ customClassName, classes, text, previewMode, block }) => {
  const sanitizedText = cleanupHTMLStyleFonts(removeScriptTagsWithSrcAttibute(text))
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div
      className={classNames(TEXT_CLASS_NAMES.containerClassName, customClassName, classes.container, {
        mounted: isMounted, // sometimes react do not update classNames on hydrate, Add extra className to trigger react rerender
      })}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.text.text,
        'data-highlighter-selector': '',
      })}
    >
      <LazyBackgroundImage block={block} isResizable />
      {sanitizedText ? (
        <div
          className={classNames(TEXT_CLASS_NAMES.textWrapperClassName, classes.textContainer, { mounted: isMounted })}
        >
          <EditTextArea value={sanitizedText} previewMode={previewMode} textFieldType={EDIT_TEXT_FIELD_TYPES.froala}>
            <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />
          </EditTextArea>
        </div>
      ) : (
        previewMode && (
          <div className={classes.noTextContainer}>
            <EditTextArea value={sanitizedText} textFieldType={EDIT_TEXT_FIELD_TYPES.froala} previewMode={previewMode}>
              <i className='fas fa-font' />
            </EditTextArea>
          </div>
        )
      )}
    </div>
  )
}

Text.propTypes = {
  /**
   * Custom Class Name
   */
  customClassName: PropTypes.string,
  /**
   * Indicates whether it's Page Builder preview of component
   */
  previewMode: PropTypes.bool,
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Text itself
   */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Text.defaultProps = {
  classes: {},
  previewMode: false,
  text: '',
  block: {},
}
