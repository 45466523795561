import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import classNames from 'classnames'
import { Progress } from 'reactstrap'

import { CoverWidget } from '@elo-kit/components/elo-ui/cover-widget'
import { PRODUCT_TYPE_IDS } from '@elo-kit/constants/products.constants'
import { MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import membershipProductsStyles from 'shared/components/content-page/preview/blocks/membership-products/MembershipProducts.styles'

/**
 * Grid Membership Product
 */
export const GridMembershipProduct = (props) => {
  const { classes, getProductLink, product, locales, showProgress, LinkComponent, previewMode } = props

  const { unsubscribed, form, covers, name } = product || {}

  const noCover = classNames(classes.noCoverContainer, 'far fa-image')
  const progress = form === PRODUCT_TYPE_IDS.course && showProgress
  const link = getProductLink(product)
  const coverLink = (((covers || [])[0] || {}).file || {}).s640

  const titleClasses = classNames(classes.productTitle, MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridTitleClassName, {
    [classes.productTitleWithProgress]: progress,
  })

  const productWidgetContainer = classNames(
    MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridItemClassName,
    classes.productWidgetContainer,
    {
      [classes.disableLink]: unsubscribed,
    }
  )

  const icon = classNames(classes.accessRestrictedGridIcon, 'fas fa-lock')

  return (
    <LinkComponent to={link} href={link} className={productWidgetContainer}>
      {unsubscribed && (
        <div className={classes.accessRestrictedGridLayer}>
          <i className={icon} />
        </div>
      )}
      <div
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.productsView,
          'data-highlighter-selector': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridItemClassName}`,
          'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
        })}
      >
        <CoverWidget img={coverLink} noImageClasses={noCover} unresponsive cover={(covers || [])[0]} />
      </div>
      <div
        className={classNames(MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridDetailsClassName, classes.productDetails)}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.productBackground,
          'data-highlighter-selector': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridDetailsClassName}`,
          'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
        })}
      >
        <div
          className={titleClasses}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.productTitle,
            'data-highlighter-selector': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridTitleClassName}`,
            'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
          })}
        >
          {name}
        </div>
        {progress && (
          <div
            className={classNames('grid-membership-progress', classes.productProgress)}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.membershipProducts.progressbar,
              'data-highlighter-selector': `.grid-membership-progress`,
              'data-highlighter-parent': `.${MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName}`,
            })}
          >
            <Progress value={product.progress} />
            <div className={classes.progressDetails}>
              <div>{locales.yourProgress}</div>
              <div>{product.progress}%</div>
            </div>
          </div>
        )}
      </div>
    </LinkComponent>
  )
}

GridMembershipProduct.displayName = 'GridMembershipProduct'
GridMembershipProduct.propTypes = {
  /** React JSS classes */
  classes: PropTypes.object,
  /** Gets product link (contentPageStore) */
  getProductLink: PropTypes.func,
  /** Product object */
  product: PropTypes.object,
  /** Locales object */
  locales: PropTypes.object,
  /** Router Link component (react-router) */
  LinkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Indicates whether progress should be shown */
  showProgress: PropTypes.bool,
  /** Preview mode in page builder */
  previewMode: PropTypes.bool,
}

/**
 * Products Grid
 */
export const GridMembershipProducts = ({
  classes,
  products,
  showProgress,
  getProductLink,
  locales,
  LinkComponent,
  previewMode,
}) => (
  <div className={classNames(MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES.gridContainerClassName, classes.productsContainer)}>
    {products.map((product, idx) => (
      <GridMembershipProduct
        product={product}
        key={`${product.id}-${idx}`}
        showProgress={showProgress}
        classes={classes}
        getProductLink={getProductLink}
        locales={locales}
        LinkComponent={LinkComponent}
        previewMode={previewMode}
      />
    ))}
  </div>
)

GridMembershipProducts.displayName = 'GridMembershipProducts'
GridMembershipProducts.propTypes = {
  /** React JSS classes */
  classes: PropTypes.object,
  /** Gets product link (contentPageStore) */
  getProductLink: PropTypes.func,
  /** Products list */
  products: PropTypes.array,
  /** Locales object */
  locales: PropTypes.object,
  /** Router Link component (react-router) */
  LinkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Indicates whether progress should be shown */
  showProgress: PropTypes.bool,
  /** Preview mode in page builder */
  previewMode: PropTypes.bool,
}

GridMembershipProducts.defaultProps = {
  getProductLink: () => {},
  LinkComponent: 'a',
  classes: {},
  products: [],
  locales: {
    yourProgress: 'react.cabinet.yourProgress',
  },
  previewMode: false,
}

export default withStyles(membershipProductsStyles)(GridMembershipProducts)
