import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { TESTIMONIALS_CLASS_NAMES, CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'
import { getThemeProps } from '@elo-kit/utils/block.utils'

import { useTestimonialsStyles } from 'shared/components/content-page/preview/blocks/testimonials/Testimonials.styles'
import TestimonialsList from './TestimonialsList'

export const Testimonials = (props) => {
  const I18n = useI18n()
  const {
    block,
    previewMode,
    showMoreButtons,
    inEdit,
    previewType,
    showMore,
    showMoreItem,
    shouldUpdateSliderHeight,
    setShowMore,
    toggleTriggerSizeUpdate,
  } = props

  const classes = useTestimonialsStyles({
    theme: getThemeProps(block),
  })
  const previewContainerClasses = classNames(
    TESTIMONIALS_CLASS_NAMES.containerClassName,
    block.content[CUSTOM_CLASS_NAME_OPTION],
    'testimonials-preview position-relative',
    classes.preview
  )

  const textContainerClasses = classNames(
    TESTIMONIALS_CLASS_NAMES.textContainerClassName,
    'position-relative testimonials-preview__lorem-block d-inline-flex mb-3',
    classes.text
  )

  const usernameContainerClasses = classNames(
    TESTIMONIALS_CLASS_NAMES.userContainerClassName,
    'h3 mt-4 mb-3 testimonials-preview__customer-name',
    classes.userName
  )

  const noContentContainerClasses = classNames(
    TESTIMONIALS_CLASS_NAMES.emptyContainerClassName,
    classes.containerPreview
  )

  return (
    <>
      <div className={previewContainerClasses}>
        {(block.content.testimonials || []).length ? (
          <TestimonialsList
            {...{
              classes,
              block,
              textContainerClasses,
              usernameContainerClasses,
              previewMode,
              showMoreButtons,
              inEdit,
              previewType,
              showMore,
              showMoreItem,
              shouldUpdateSliderHeight,
              setShowMore,
              toggleTriggerSizeUpdate,
            }}
          />
        ) : (
          <>
            <LazyBackgroundImage block={block} position='top' isResizable />
            <div className={noContentContainerClasses}>
              <i
                className={classNames('fas fa-chevron-left')}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.blockSlider,
                  'data-highlighter-selector': '',
                })}
              />
              <div className='col-7'>
                <div
                  className={classNames(
                    'rounded-circle testimonials-preview__background-image m-auto rounded-circle d-flex'
                  )}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.entries,
                    'data-highlighter-selector': '',
                  })}
                >
                  <i className='fas fa-user m-auto' />
                </div>
                <div
                  className={usernameContainerClasses}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.usernames,
                    'data-highlighter-selector': '',
                  })}
                >
                  {I18n.t('shared.common.customer_name')}
                </div>
                <div
                  className={textContainerClasses}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.testimonialsText,
                    'data-highlighter-selector': '',
                  })}
                >
                  <i className='fas fa-quote-left' />
                  <div className={classes.comment}>{I18n.t('react.shared.common.lorem')}</div>
                  <i className='fas fa-quote-right' />
                </div>
              </div>
              <i
                className={classNames('fas fa-chevron-right')}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.testimonials.blockSlider,
                  'data-highlighter-selector': '',
                })}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

Testimonials.propTypes = {
  previewMode: PropTypes.bool,
  previewType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inEdit: PropTypes.bool,
  showMoreButtons: PropTypes.bool,
  showMore: PropTypes.bool,
  showMoreItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  shouldUpdateSliderHeight: PropTypes.bool,
  setShowMore: PropTypes.func,
  toggleTriggerSizeUpdate: PropTypes.func,
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    content: PropTypes.object,
  }),
}

Testimonials.defaultProps = {
  previewMode: false,
  block: {
    content: {},
  },
  classes: {},
}
