import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { isEmpty } from 'utils/validatorsShared.utils'
import { getFirstCoverUrl, getPricingPlan, cutDescription } from '@elo-kit/utils/product.utils'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'
import { FREE_PRICE } from 'constants/general.constants'
import { POPULAR_PRODUCTS_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'

import popularProductsStyles from 'shared/components/content-page/preview/blocks/popular-products/PopularProducts.styles'

export const ProductPrice = (props) => {
  const I18n = useI18n()
  const {
    classes,
    mobile,
    product,
    getUnit,
    convertToPriceWithoutCurrency,
    previewMode,
    netDefaultText = I18n.t('shared.common.net'),
  } = props

  const containerClasses = classNames({
    [classes.listPriceContainer]: !mobile,
    [classes.mobileListPriceContainer]: mobile,
  })

  const currencySign = getUnit(product.displayCurrencyId)

  const { useNetPrice } = getPricingPlan(product)

  return (
    <div className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.listPriceContainerClassName, containerClasses)}>
      {Number(product.displayOldPrice) > 0 && (
        <div
          className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.listOldPriceClassName, classes.oldPriceContainer)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.oldPrice,
            'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.listOldPriceClassName}`,
            'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName}`,
          })}
        >
          <div className={classNames(classes.oldPrice)}>
            <div>
              <span translate='no'>{convertToPriceWithoutCurrency(product.displayOldPrice)}</span>
              <span>{currencySign}</span>
              <hr className={classes.oldPriceLine} />
            </div>
          </div>
        </div>
      )}
      <span
        className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.listPriceClassName, classes.price)}
        translate='no'
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.priceBlock,
          'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.listPriceClassName}`,
          'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName}`,
        })}
      >
        {product.free ? FREE_PRICE : convertToPriceWithoutCurrency(product.displayPrice)}
      </span>
      {!product.free && (
        <span
          className={classNames(classes.currency, POPULAR_PRODUCTS_CLASS_NAMES.listCurrencyClassName)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.currency,
            'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.listCurrencyClassName}`,
            'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName}`,
          })}
        >
          {currencySign}
        </span>
      )}
      {useNetPrice && <span className={classes.net}>{`(${netDefaultText})`}</span>}
    </div>
  )
}

ProductPrice.propTypes = {
  /** Classes object for list seller products */
  classes: PropTypes.object,
  /** Product price for mobile */
  mobile: PropTypes.bool,
  /** Product */
  product: PropTypes.object,
  /** Default free text */
  freeDefaultText: PropTypes.string,
  /** Default net text */
  netDefaultText: PropTypes.string,
  /** Get unit function received from currency store */
  getUnit: PropTypes.func,
  /** Convert price without currency function */
  convertToPriceWithoutCurrency: PropTypes.func,
}
ProductPrice.defaultProps = {
  product: {},
  getUnit: /* istanbul ignore next */ () => {}, // currenciesStore.getUnit
  convertToPriceWithoutCurrency: /* istanbul ignore next */ () => {}, // (value) => value.formatMoney(2, ',', '.')
}

export const ListPopularProducts = (props) => {
  const {
    classes,
    products,
    productsContent,
    handleButtonClick,
    buttonText,
    freeButtonText,
    buttonAnimation,
    defaultButtonText,
    getUnit,
    freeDefaultText,
    netDefaultText,
    convertToPriceWithoutCurrency,
    previewMode,
  } = props

  return (
    <div className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName, classes.list)}>
      {products.map((product) => {
        const { description, free, name } = product
        const titleClasses = classNames(
          classes.productName,
          classes.listProductTitle,
          POPULAR_PRODUCTS_CLASS_NAMES.listTitleClassName
        )
        const buttonClasses = classNames(
          classes.button,
          classes.listButton,
          POPULAR_PRODUCTS_CLASS_NAMES.listButtonClassName,
          {
            [classes[buttonAnimation]]: !!buttonAnimation,
          }
        )

        const productDescription = cutDescription(description)

        const productCovers = productsContent?.find((image) => image.id === product.covers?.[0]?.id)?.attributes || {}

        const btnText = free ? freeButtonText : buttonText

        return (
          <div className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.listItemClassName, classes.item)} key={product.id}>
            <div className={classes.productDetailsContainer}>
              <div
                className={classNames(
                  classes.listBackgroundImageContainer,
                  POPULAR_PRODUCTS_CLASS_NAMES.listImageClassName
                )}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.selectedProducts,
                  'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.listImageClassName}`,
                  'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName}`,
                })}
              >
                {getFirstCoverUrl(product, 's640') || !isEmpty(productCovers) ? (
                  <LazyBackgroundImage
                    isResizable
                    block={{
                      cover: {
                        file: {
                          original: product.covers?.[0]?.file?.s640 || productCovers.s640,
                          s640: product.covers?.[0]?.file?.s100 || productCovers.s100,
                        },
                      },
                    }}
                    wrapperClassName={classes.productCover}
                  />
                ) : (
                  <i className='far fa-image m-auto' />
                )}
              </div>
              <div
                className={classNames(POPULAR_PRODUCTS_CLASS_NAMES.listDetailsClassName, classes.listProductDetails)}
              >
                <div
                  className={titleClasses}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productsTitles,
                    'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.listTitleClassName}`,
                    'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName}`,
                  })}
                >
                  {name}
                </div>
                <div
                  className={classNames(classes.description, POPULAR_PRODUCTS_CLASS_NAMES.listDescriptionClassName)}
                  dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(productDescription) }}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.description,
                    'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.listDescriptionClassName}`,
                    'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName}`,
                  })}
                />
                <ProductPrice
                  mobile
                  {...{
                    product,
                    classes,
                    getUnit,
                    freeDefaultText,
                    netDefaultText,
                    convertToPriceWithoutCurrency,
                    previewMode,
                  }}
                />
              </div>
            </div>
            <div className={classes.payInfoContainer}>
              <ProductPrice
                {...{
                  product,
                  classes,
                  getUnit,
                  freeDefaultText,
                  netDefaultText,
                  convertToPriceWithoutCurrency,
                  previewMode,
                }}
              />
              <button
                type='button'
                onClick={(e) => handleButtonClick(product, e)}
                className={buttonClasses}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.popularProducts.productButton,
                  'data-highlighter-selector': `.${POPULAR_PRODUCTS_CLASS_NAMES.listButtonClassName}`,
                  'data-highlighter-parent': `.${POPULAR_PRODUCTS_CLASS_NAMES.listContainerClassName}`,
                })}
              >
                {btnText || defaultButtonText}
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

ListPopularProducts.propTypes = {
  /** Classes object for list seller products */
  classes: PropTypes.object,
  /** Products for list view */
  products: PropTypes.array,
  /** Buy now button handle function */
  handleButtonClick: PropTypes.func,
  /** Custom button text */
  buttonText: PropTypes.string,
  /** Button text for free product */
  freeButtonText: PropTypes.string,
  /** Animation for button */
  buttonAnimation: PropTypes.string,
  /** Default button text */
  defaultButtonText: PropTypes.string,
  /** Get unit function received from currency store */
  getUnit: PropTypes.func,
  /** Default free text */
  freeDefaultText: PropTypes.string,
  /** Default net text */
  netDefaultText: PropTypes.string,
  /** Convert price without currency function */
  convertToPriceWithoutCurrency: PropTypes.func,
  /** Preview mode in page builder */
  previewMode: PropTypes.bool,
}
ListPopularProducts.defaultProps = {
  defaultButtonText: 'Buy now', // I18n.t('shared.common.buy_now')
  classes: {},
  products: [],
  previewMode: false,
}

export default withStyles(popularProductsStyles)(ListPopularProducts)
