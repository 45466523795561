import React, { Component, useEffect, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'

import filesStyles from 'shared/components/content-page/preview/blocks/files/Files.styles'

import { FileIcon } from '@elo-kit/components/icons/FileIcon'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'
import { getExtension } from '@elo-kit/utils/files.utils'
import { getThemeProps } from '@elo-kit/utils/block.utils'

import { DEFAULT_DURATION } from '@elo-kit/constants/general.constants'

import { FILE_CLASS_NAMES, CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'

import { BUTTON_ANIMATIONS } from '@elo-kit/constants/animations.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

/**
 * FilesContainer - Container of files
 */
export const FilesContainer = ({ classes, children, isFile, previewMode, block }) => (
  <div className={`${classes.container} content-block-filelist`}>
    <LazyBackgroundImage block={block} isResizable />

    {isFile || !previewMode ? (
      <div className={classes.filesContainer}>{children}</div>
    ) : (
      <div className={classes.noFileContainer}>
        <i className='fas fa-download' />
      </div>
    )}
  </div>
)

FilesContainer.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Indicates whether it's Page Builder preview of component
   */
  previewMode: PropTypes.bool,
  /**
   * Indicates whether file is provided
   */
  isFile: PropTypes.bool,
  /**
   * Container children
   */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node, PropTypes.string]),
}

FilesContainer.defaultProps = {
  classes: {},
  previewMode: false,
  isFile: false,
}

/**
 * File - File Item
 */
export const File = (props) => {
  const I18n = useI18n()
  const {
    classes,
    good,
    block: {
      contentPageId,
      id: blockId,
      content,
      content: { buttonAnimation },
    },
    getDownloadLink,
    previewMode,
    audioPlayer,
    downloadButtonText = I18n.t('react.shared.button.download'),
  } = props

  const audioRef = useRef(null)

  useEffect(() => {
    audioPlayer?.init(audioRef.current)
  }, [audioRef])

  const {
    digital: { file, name },
  } = good

  const fileName = name || (file && file.name) || ''
  const extension = getExtension(fileName) || getExtension(file && file.name)
  const fileExtension = ((extension && extension[1]) || '').toLowerCase()
  const buttonClasses = classNames(classes.button, {
    [classes[buttonAnimation]]: !!buttonAnimation,
    [classes[BUTTON_ANIMATIONS.transparency]]: !buttonAnimation,
  })
  const downloadLink = getDownloadLink(blockId, contentPageId, good.id)
  const fileWrapperClasses = classNames(
    FILE_CLASS_NAMES.containerClassName,
    content[CUSTOM_CLASS_NAME_OPTION],
    'file-wrapper',
    classes.fileContainer
  )

  return (
    <div className={fileWrapperClasses}>
      {good.digital.isAudio ? (
        <div
          ref={audioRef}
          className={classNames('audio-player', classes.fileDetails)}
          data-id={good.id}
          data-style='full'
          data-duration-full={file.duration}
          data-speed={1.0}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.file.filesList,
            'data-highlighter-selector': '',
          })}
        >
          <i className={classNames('fas audio-button', classes.audioButton)}>
            <audio className='audio-play' preload='none'>
              <source src={good.digital.file.original} type={good.digital.file.contentType} />
            </audio>
          </i>

          <div className={classes.fileInfoContainer}>
            <div className={classNames('file', classes.fileName)}>
              <div
                className={classNames('audio-name')}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.file.title,
                  'data-highlighter-selector': '',
                })}
              >
                {name || file.name}
              </div>
              <div className='duration'>{DEFAULT_DURATION}</div>
            </div>
            <div className={`progressbar d-none ${classes.audioProgressBar}`} />
          </div>
        </div>
      ) : (
        <div
          className={classes.fileDetails}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.file.filesList,
            'data-highlighter-selector': '',
          })}
        >
          <FileIcon extension={fileExtension} file={file} />
          <div
            className={classNames(classes.fileName)}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.file.title,
              'data-highlighter-selector': '',
            })}
          >
            {fileName}
          </div>
        </div>
      )}
      <div>
        <a
          target='_blank'
          className={buttonClasses}
          href={!previewMode ? downloadLink : undefined}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.file.buttonStyle,
            'data-highlighter-selector': '',
          })}
        >
          {downloadButtonText}
        </a>
      </div>
    </div>
  )
}

File.propTypes = {
  /**
   * React JSS classes
   */
  classes: PropTypes.object,
  /**
   * Function that returns download URL
   */
  getDownloadLink: PropTypes.func,
  /**
   * Download Button Text
   */
  downloadButtonText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Object that contains file info
   */
  good: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    digital: PropTypes.object,
  }),
  /**
   * Object that contains content block info
   */
  block: PropTypes.shape({
    contentPageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.object,
    digital: PropTypes.object,
  }),
  /**
   * Indicates whether it's Page Builder preview of component
   */
  previewMode: PropTypes.bool,
}

File.defaultProps = {
  classes: {},
  good: {},
  block: {},
  getDownloadLink: /* istanbul ignore next */ () => {
    /* no-op */
  },
}

export const FilesContainerWithStyles = withStyles(filesStyles)(FilesContainer)
export const FileWithStyles = withStyles(filesStyles)(File)

/**
 * Files - Page Builder's Files Block with react-jss injection
 */
export class Files extends Component {
  render() {
    const { block, previewMode, downloadButtonText, getDownloadLink, AudioPlayer } = this.props

    return (
      <ThemeProvider theme={getThemeProps(block)}>
        <FilesContainerWithStyles block={block} isFile={!!block.goods.length} previewMode={previewMode}>
          {block.goods.map((good, key) => (
            <FileWithStyles
              key={key}
              {...{
                block,
                good,
                key,
                downloadButtonText,
                getDownloadLink,
                previewMode,
                audioPlayer: AudioPlayer,
              }}
            />
          ))}
        </FilesContainerWithStyles>
      </ThemeProvider>
    )
  }
}

Files.propTypes = {
  /**
   * Instance of AudioPlayer
   */
  AudioPlayer: PropTypes.object,
  /**
   * Function that returns download URL
   */
  getDownloadLink: PropTypes.func,
  /**
   * Indicates whether it's Page Builder preview of component
   */
  previewMode: PropTypes.bool,
  /**
   * Content block object
   */
  block: PropTypes.shape({
    goods: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    content: PropTypes.object,
  }),
  /**
   * Download Button Text
   */
  downloadButtonText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

Files.defaultProps = {
  getDownloadLink: /* istanbul ignore next */ () => {
    /* no-op */
  },
  previewMode: false,
  block: {},
}
